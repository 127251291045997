@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.reveal {
	position: relative;
	transform: translateY(50px);
	opacity: 0;
	transition: 0.3s all ease;
}
.reveal.active {
	transform: translateY(0);
	opacity: 1;
}

.navbar {
	z-index: 99;
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50px);
	opacity: 0;
	transition: 0.3s all ease;
}

.navbar.active {
	transform: translate(-50%, 0);
	opacity: 1;
}

.bg {
  opacity: 0;
	transition: 0.3s all ease;
}

.bg.active {
  opacity: 1;
	transition: 0.3s all ease;
}
